import logo from './logo.svg';
import { Box } from '@mui/material';
import React, { useEffect, useState } from 'react';
import Dashboard from './Dashboard';
import Login from '../src/Login'
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import Examination from './Examination';
import Start_Exampage from './Start_Exampage';
import Settings from './Settings';
import Certificates from './Certificates';
import Payments from './Payments';
import Tickets from './Tickets';



function App() {
  return (
    <Box sx={{backgroundColor:'#f8f9ff'}}>
    <React.StrictMode>
    <RouterProvider router={createBrowserRouter([
      
    {
      path: "/" ,
      element:<Login/>,
    },
    {
      path: "/register" ,
      element:<Login/>,
    },
    {
    path:"/dashboard",
    element:<Dashboard/>,
    },
    {
      path:"/test",
      element:<Examination/>,
      },
      {
        path:"/test/:id",
        element:<Start_Exampage/>,
      },
      
      {
        path:"settings",
        element:<Settings/>,
      },
      {
        path:"certificates",
        element:<Certificates/>,
      },
      {
        path:"payments",
        element:<Payments/>,
      },
      {
        path:"tickets",
        element:<Tickets/>,
      },
 
])} />

</React.StrictMode>
</Box>

  );
}

export default App;
