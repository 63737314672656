import React, { Component } from "react";
import { Sidebarc } from "../src/Sidebar";
import { Appheaderc } from "./Appheader";
import { Button, Paper, Typography } from "@mui/material";
import { Box, Backdrop, CircularProgress } from "@mui/material";
import Grid from "@mui/material/Grid";
import DownloadIcon from '@mui/icons-material/Download';
import {
  Tooltip,
  IconButton,
  TableBody,
  Table,
  Modal,
  MenuItem,
  TableContainer,
  TablePagination,
  Divider,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import base_url from "./baseurl";
import "react-toastify/dist/ReactToastify.css";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import DriveFileMoveIcon from "@mui/icons-material/DriveFileMove";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
import moment from 'moment'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Link } from "react-router-dom";



const drawerWidth = 235;
class Certificates extends Component {
  constructor(props) {
    super(props);
    this.state = {
     

    
    };
  }

  componentDidMount() {

/*
    fetch(`${base_url.base_url}/daysLoginCount`, {
      headers: {
        "Content-Type": "application/json",
      },
      method: "GET",
    })
      .then((res) => {
        return res.json();
      })
      .then((result) => {
        this.setState({
          todayLogin: result.todayLogin,
          totalLoginIn15days: result.totalLoginIn15days,
          totalLoginIn30days: result.totalLoginIn30days,
          allusers: result.allusers,
          login7days : result.login7days
        });
      });

*/

  }

  render() {
    return (
      <div>
        <Box sx={{ display: "flex" }}>
          <Sidebarc />
          <Box
            sx={{ width: { sm: `calc(100% - ${drawerWidth}px)`, xs: "100%" } }}
          >
            <Box sx={{ p: { xs: 1, sm: 3 }, mt: 6 }}>
              <Typography
                style={{
                  fontWeight: "bold",
                  color: "black",
                  fontSize: 16,
                  marginTop: 5,
                  marginBottom: 5,
                  marginLeft: 10,
                }}
              >
                 Generate Your Exam certificate 
              </Typography>









<Box>
 
<Paper elevation={0} sx={{ width: "100%", minHeight: 200, mt: 2 }}>
                 <Box sx={{ mt: 0, padding: 2 }}>
                  <TableContainer component={Box}>
                    <Table
                      sx={{ minWidth: 720 }}
                      size="small"
                      aria-label="a dense table"
                    >
                      <TableHead>
                        <TableRow>
                          <TableCell
                            align="left"
                            sx={{
                              ml:10,
                              fontSize: 14,
                              fontWeight: "600",
                              color: "#919191",
                            }}
                          >
                            sr. no
                          </TableCell>
                          <TableCell
                            align="left"
                            sx={{
                              fontSize: 14,
                              fontWeight: "600",
                              color: "#919191",
                            }}
                          >
                           Exam description 
                          </TableCell>
                            
                          <TableCell
                            align="right"
                            sx={{
                              fontSize: 14,
                              fontWeight: "600",
                              color: "#919191",
                            }}
                          >
                            Exam date
                          </TableCell>
                        
                          <TableCell
                            align="right"
                            sx={{
                              fontSize: 14,
                              fontWeight: "600",
                              color: "#919191",
                            }}
                          >
                          score(%)
                          </TableCell>

                          <TableCell
                            align="right"
                            sx={{
                              fontSize: 14,
                              fontWeight: "600",
                              color: "#919191",
                            }}
                          >
                          Result
                          </TableCell>
                          <TableCell
                            align="right"
                            sx={{
                              fontSize: 14,
                              fontWeight: "600",
                              color: "#919191",
                            }}
                          >
                            Action
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {[1,2,3,4,5].map((row,index) => (
                          <TableRow
                            key={row._id}
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 } , backgroundColor : index % 2?'#f8f8f8':'#fff'
                            }}
                          >
                            <TableCell
                              component="th"
                              scope="row"
                              sx={{ color: "#42526e", fontSize: 13 }}
                            >
                                {index + 1}
                            </TableCell>
                        
                            <TableCell align="left" sx={{ color: "#42526e",fontSize:12,fontWeight:'600' }}>
                              This is examination for upsc 2025
                            </TableCell>  
                            
                          <TableCell align="right" sx={{ color: "#42526e",fontSize:12 }}>
                           {moment(row.created_at).format('YYYY-MM-DD hh:mm')}
                            </TableCell>
                            <TableCell align="right" sx={{ color: "#42526e" ,fontSize:12}}>
                             67%
                            </TableCell>
                            <TableCell align="right" sx={{ color: "#42526e" ,fontSize:12}}>
                             Pass
                            </TableCell>
                            <TableCell align="right">
                              <Box
                                sx={{
                                  display: "flex",
                                  flexDirection: "row",
                                  justifyContent: "right",
                                }}
                              >
                                 <Tooltip title="Download Certificate">
                                  <IconButton
                                    size="small"
                                  >
                                    <DownloadIcon
                                      sx={{
                                        color: "#8787c5",
                                        height: 15,
                                        width: 15,
                                      }}
                                    />
                                  </IconButton>
                                </Tooltip>

                                <Tooltip title="Delete">
                                  <IconButton
                                    size="small"
                                  >
                                    <DeleteForeverIcon
                                      sx={{
                                        color: "#f29494",
                                        height: 15,
                                        width: 15,
                                      }}
                                    />
                                  </IconButton>
                                </Tooltip>
                              </Box>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <Divider />
                  <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={this.state.counts}
                    rowsPerPage={this.state.rowsPerPage}
                    page={this.state.page}
                    onPageChange={this.handleChangePage}
                    onRowsPerPageChange={this.handleChangeRowsPerPage}
                  />
                </Box>
              </Paper>

</Box>





















            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              position: "fixed",
              top: 0,
              left: { xs: 0, sm: 235 },
            }}
          >
            <Appheaderc />
          </Box>

        </Box>
      </div>
    );
  }
}

export default Certificates;
