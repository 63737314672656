import React, { Component } from "react";
import { Sidebarc } from "../src/Sidebar";
import { Appheaderc } from "./Appheader";
import { Button, Paper, Typography } from "@mui/material";
import { Box, Backdrop, CircularProgress } from "@mui/material";
import Grid from "@mui/material/Grid";
import DownloadIcon from '@mui/icons-material/Download';
import {
  Tooltip,
  IconButton,
  TableBody,
  Table,
  Modal,
  MenuItem,
  TableContainer,
  TablePagination,
  Divider,
  TableCell,
  TableHead,
  Alert,
  TableRow,
} from "@mui/material";
import base_url from "./baseurl";
import "react-toastify/dist/ReactToastify.css";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import DriveFileMoveIcon from "@mui/icons-material/DriveFileMove";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
import moment from 'moment'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Link } from "react-router-dom";
import PaidIcon from '@mui/icons-material/Paid';
import AccountCircleIcon from "@mui/icons-material/AccountCircle";



const drawerWidth = 235;
class Payments extends Component {
  constructor(props) {
    super(props);
    this.state = {
      todayLogin: 10,
      totalLoginIn15days: 5,
      totalLoginIn30days: 45,
      allusers: 50,


      login7days : 40,

    
    };
  }

  componentDidMount() {

/*
    fetch(`${base_url.base_url}/daysLoginCount`, {
      headers: {
        "Content-Type": "application/json",
      },
      method: "GET",
    })
      .then((res) => {
        return res.json();
      })
      .then((result) => {
        this.setState({
          todayLogin: result.todayLogin,
          totalLoginIn15days: result.totalLoginIn15days,
          totalLoginIn30days: result.totalLoginIn30days,
          allusers: result.allusers,
          login7days : result.login7days
        });
      });

*/

  }

  render() {
    return (
      <div>
        <Box sx={{ display: "flex" }}>
          <Sidebarc />
          <Box
            sx={{ width: { sm: `calc(100% - ${drawerWidth}px)`, xs: "100%" } }}
          >
            <Box sx={{ p: { xs: 1, sm: 3 }, mt: 6 }}>
              <Typography
                style={{
                  fontWeight: "bold",
                  color: "black",
                  fontSize: 16,
                  marginTop: 5,
                  marginBottom: 5,
                  marginLeft: 10,
                }}
              >
               Payments
              </Typography>





              <Alert severity="error"   action={
    <Button variant="contained" size="small">
    ADD AMOUNT
    </Button>
  }>Your wallet amount is running low please add fund.</Alert>




<Box sx={{mt:2}}>
              <Grid container spacing={{ xs: 1, sm: 2, md: 2 }}>
                <Grid item xs={12} sm={6} md={3}>
                  <Paper
                    elevation={1}
                    sx={{
                      height: 110,
                      backgroundColor: "#008ffb",
                      width: "100%",
                      borderRadius: 2,
                    }}
                  >
                    <Box sx={{ padding: 1.5 }}>
                      <Typography
                        sx={{
                          fontSize: 12,
                          fontWeight: "550",
                          fontFamily: "sans-serif",
                          color: "#fff",
                        }}
                      >
                        TOTAL TRANSACTIONS
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: 19,
                          fontWeight: "550",
                          fontFamily: "sans-serif",
                          color: "#fff",
                          mt: 1,
                        }}
                      >
                        {this.state.todayLogin}
                      </Typography>

                      <Box
                        sx={{
                          width: "100%",
                          height: 45,
                          display: "flex",
                          justifyContent: "right",
                        }}
                      >
                        <Box
                          sx={{
                            height: 40,
                            width: 40,
                            backgroundColor: "#e2f1ff",
                            borderRadius: 3,
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <AccountCircleIcon
                            sx={{ height: 30, width: 30, color: "#008ffb" }}
                          />
                        </Box>
                      </Box>
                    </Box>
                  </Paper>
                </Grid>

                <Grid item xs={12} sm={6} md={3}>
                  <Paper
                    elevation={1}
                    sx={{
                      height: 110,
                      backgroundColor: "#008ffb",
                      width: "100%",
                      borderRadius: 2,
                    }}
                  >
                    <Box sx={{ padding: 1.5 }}>
                      <Typography
                        sx={{
                          fontSize: 12,
                          fontWeight: "550",
                          fontFamily: "sans-serif",
                          color: "#fff",
                        }}
                      >
                        TOTAL AMOUNT SPEND
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: 19,
                          fontWeight: "550",
                          fontFamily: "sans-serif",
                          color: "#fff",
                          mt: 1,
                        }}
                      >
                        {this.state.totalLoginIn15days}₹
                      </Typography>
                      <Box
                        sx={{
                          width: "100%",
                          height: 45,
                          display: "flex",
                          justifyContent: "right",
                        }}
                      >
                        <Box
                          sx={{
                            height: 40,
                            width: 40,
                            backgroundColor: "#e2f1ff",
                            borderRadius: 3,
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <AccountCircleIcon
                            sx={{ height: 30, width: 30, color: "#008ffb" }}
                          />
                        </Box>
                      </Box>
                    </Box>
                  </Paper>
                </Grid>

                <Grid item xs={12} sm={6} md={3}>
                  <Paper
                    elevation={1}
                    sx={{
                      height: 110,
                      backgroundColor: "#008ffb",
                      width: "100%",
                      borderRadius: 2,
                    }}
                  >
                    <Box sx={{ padding: 1.5 }}>
                      <Typography
                        sx={{
                          fontSize: 12,
                          fontWeight: "550",
                          fontFamily: "sans-serif",
                          color: "#fff",
                        }}
                      >
                        REMAINING AMOUNT
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: 19,
                          fontWeight: "550",
                          fontFamily: "sans-serif",
                          color: "#fff",
                          mt: 1,
                        }}
                      >
                       {this.state.totalLoginIn30days}₹
                      </Typography>
                      <Box
                        sx={{
                          width: "100%",
                          height: 45,
                          display: "flex",
                          justifyContent: "right",
                        }}
                      >
                        <Box
                          sx={{
                            height: 40,
                            width: 40,
                            backgroundColor: "#e2f1ff",
                            borderRadius: 3,
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <PaidIcon
                            sx={{ height: 30, width: 30, color: "#008ffb" }}
                          />
                        </Box>
                      </Box>
                    </Box>
                  </Paper>
                </Grid>

                <Grid item xs={12} sm={6} md={3}>
                  <Paper
                    elevation={1}
                    sx={{
                      height: 110,
                      backgroundColor: "#008ffb",
                      width: "100%",
                      borderRadius: 2,
                    }}
                  >
                    <Box sx={{ padding: 1.5 }}>
                      <Typography
                        sx={{ fontSize: 12, fontWeight: "bold", color: "#fff" }}
                      >
                       ADD AMOUNT IN WALLET                     </Typography>
                    <Typography>.</Typography>
                      <Box
                        sx={{
                          width: "100%",
                          height: 45,
                          display: "flex",
                          justifyContent: "right",
                        }}
                      >
                        <Box
                          sx={{
                            height: 40,
                            minWidth: 40,
                            padding:0.3,
                            backgroundColor: "transparent",
                            borderRadius: 3,
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                           <Button size="small" variant="contained" sx={{backgroundColor:'#2cbd96',textTransform:'none'}}>Add Payments (INR)</Button>
                        </Box>
                      </Box>
                    </Box>
                  </Paper>
                </Grid>
              </Grid>
</Box>



<Box>
 
<Paper elevation={0} sx={{ width: "100%", minHeight: 200, mt: 2 }}>
                 <Box sx={{ mt: 0, padding: 2 }}>
                  <TableContainer component={Box}>
                    <Table
                      sx={{ minWidth: 720 }}
                      size="small"
                      aria-label="a dense table"
                    >
                      <TableHead>
                        <TableRow>
                          <TableCell
                            align="left"
                            sx={{
                              ml:10,
                              fontSize: 14,
                              fontWeight: "600",
                              color: "#919191",
                            }}
                          >
                            sr. no
                          </TableCell>
                          <TableCell
                            align="left"
                            sx={{
                              fontSize: 14,
                              fontWeight: "600",
                              color: "#919191",
                            }}
                          >
                           Payment Gateway
                          </TableCell>
                            
                          <TableCell
                            align="right"
                            sx={{
                              fontSize: 14,
                              fontWeight: "600",
                              color: "#919191",
                            }}
                          >
                            Payment  Date
                          </TableCell>
                        
                          <TableCell
                            align="right"
                            sx={{
                              fontSize: 14,
                              fontWeight: "600",
                              color: "#919191",
                            }}
                          >
                          Transaction id
                          </TableCell>

                          <TableCell
                            align="right"
                            sx={{
                              fontSize: 14,
                              fontWeight: "600",
                              color: "#919191",
                            }}
                          >
                        Amount(INR)
                          </TableCell>

                          <TableCell
                            align="right"
                            sx={{
                              fontSize: 14,
                              fontWeight: "600",
                              color: "#919191",
                            }}
                          >
                        Status
                          </TableCell>
                       
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {[1,2,3,4,5].map((row,index) => (
                          <TableRow
                            key={row._id}
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 } , backgroundColor : index % 2?'#f8f8f8':'#fff'
                            }}
                          >
                            <TableCell
                              component="th"
                              scope="row"
                              sx={{ color: "#42526e", fontSize: 13 }}
                            >
                                {index + 1}
                            </TableCell>
                        
                            <TableCell align="left" sx={{ color: "#42526e",fontSize:12,fontWeight:'600' }}>
                              Razor pay payments
                            </TableCell>  
                            
                          <TableCell align="right" sx={{ color: "#42526e",fontSize:12 }}>
                           {moment(row.created_at).format('YYYY-MM-DD hh:mm')}
                            </TableCell>
                            <TableCell align="right" sx={{ color: "#42526e" ,fontSize:12}}>
                             pwter64hwhyggey
                            </TableCell>
                            <TableCell align="right" sx={{ color: "#42526e" ,fontSize:12}}>
                             45.66 INR
                            </TableCell>
                            <TableCell align="right" sx={{ color: "#42526e" ,fontSize:12}}>
                             Success
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <Divider />
                  <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={this.state.counts}
                    rowsPerPage={this.state.rowsPerPage}
                    page={this.state.page}
                    onPageChange={this.handleChangePage}
                    onRowsPerPageChange={this.handleChangeRowsPerPage}
                  />
                </Box>
              </Paper>

</Box>





















            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              position: "fixed",
              top: 0,
              left: { xs: 0, sm: 235 },
            }}
          >
            <Appheaderc />
          </Box>

        </Box>
      </div>
    );
  }
}

export default Payments;
