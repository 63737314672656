import React, { Component } from "react";
import { Sidebarc } from "../src/Sidebar";
import { Appheaderc } from "./Appheader";
import { Button, Checkbox, Paper, Typography } from "@mui/material";
import { Box, Backdrop, CircularProgress } from "@mui/material";
import Grid from "@mui/material/Grid";
import {
  Tooltip,
  IconButton,
  TableBody,
  Table,
  Modal,
  MenuItem,
  TableContainer,
  TablePagination,
  Divider,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import base_url from "./baseurl";
import "react-toastify/dist/ReactToastify.css";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import DriveFileMoveIcon from "@mui/icons-material/DriveFileMove";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
import moment from 'moment'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';



const drawerWidth = 235;
class Start_Exampage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      todayLogin: 10,
      totalLoginIn15days: 5,
      totalLoginIn30days: 45,
      allusers: 50,


      login7days : 40,

    
    };
  }

  componentDidMount() {

/*
    fetch(`${base_url.base_url}/daysLoginCount`, {
      headers: {
        "Content-Type": "application/json",
      },
      method: "GET",
    })
      .then((res) => {
        return res.json();
      })
      .then((result) => {
        this.setState({
          todayLogin: result.todayLogin,
          totalLoginIn15days: result.totalLoginIn15days,
          totalLoginIn30days: result.totalLoginIn30days,
          allusers: result.allusers,
          login7days : result.login7days
        });
      });

*/

  }

  render() {
    return (
      <div>
        <Box sx={{ display: "flex" }}>
          <Sidebarc />
          <Box
            sx={{ width: { sm: `calc(100% - ${drawerWidth}px)`, xs: "100%" } }}
          >
            <Box sx={{ p: { xs: 1, sm: 3 }, mt: 6 }}>
              <Typography
                style={{
                  fontWeight: "bold",
                  color: "black",
                  fontSize: 16,
                  marginTop: 5,
                  marginBottom: 5,
                  marginLeft: 10,
                }}
              >
               Start Examination 
              </Typography>









<Box sx={{mt:2}}>
<Paper elevation={0}  sx={{minHeight:200,width:'100%'}}>
<Box sx={{margin:{xs:2,sm:2}}}>

<br/>
<Box sx={{display:'flex',flexDirection:'row'}}>

<Box elevation={0} sx={{height:40,width:40,borderRadius:8,border:0.5,borderColor:'#0d6efd',borderWidth:0.5,display:'flex',justifyContent:'center',alignItems:'center'}}>
     <Typography sx={{fontWeight:'800',color:'#0d6efd',padding:4}}>12</Typography>
</Box>

<Box sx={{ml:4}}>
    <Typography  sx={{fontSize:14,fontWeight:'500',textAlign:'justify'}}>This page provides sample checkboxes for practice test automation. Checkboxes are commonly used in both web and mobile applications to allow users to enable</Typography>
    <Typography sx={{fontSize:14,fontWeight:'600',mt:0.5,mb:0.5}}>-- OR --</Typography>
    <Typography  sx={{fontSize:14,fontWeight:'500',textAlign:'justify'}}>ब्रिटिश आणि अमेरिकन इंग्रजी या दोन्हीमध्ये, हजारो समानार्थी आणि विरुद्धार्थी शब्दामधील फरकाचे स्पष्ट स्पष्टीकरण आणि उदाहरणे मिळवा. सर्च बारवर एखादा शब्द शोधा, सर्वात लोकप्रिय लेखांपैकी एक वापरून पहा किंवा ज्ञानकोश A-Z ब्राउझ करा.ब्रिटिश आणि अमेरिकन इंग्रजी या दोन्हीमध्ये, हजारो समानार्थी आणि विरुद्धार्थी शब्दामधील फरकाचे स्पष्ट स्पष्टीकरण आणि उदाहरणे मिळवा. सर्च बारवर एखादा शब्द शोधा, सर्वात लोकप्रिय लेखांपैकी एक वापरून पहा किंवा ज्ञानकोश A-Z ब्राउझ करा.</Typography>
</Box>

</Box>


<Box sx={{display:'flex',flexDirection:'column',mt:3}}>
    <Typography sx={{fontSize:14,fontWeight:'500',textAlign:'justify',ml:8,mb:0.5}}> <Checkbox size="small"/> Democracy and soil too</Typography>
    <Typography sx={{fontSize:14,fontWeight:'500',textAlign:'justify',ml:8,mb:0.5}}> <Checkbox size="small"/> Democracy and soil too</Typography>
    <Typography sx={{fontSize:14,fontWeight:'500',textAlign:'justify',ml:8,mb:0.5}}> <Checkbox size="small"/> Democracy and soil too</Typography>
    <Typography sx={{fontSize:14,fontWeight:'500',textAlign:'justify',ml:8,mb:0.5}}> <Checkbox size="small"/> Democracy and soil too</Typography>
</Box>



<Box sx={{display:'flex',justifyContent:'space-between',flexDirection:'row',ml:9,mt:5}}>
<Button variant="contained" size="small" sx={{textTransform:'none'}}>Previous</Button>
<Button variant="contained" size="small"  sx={{textTransform:'none'}}>Next</Button>
</Box>



<br/>




</Box>
</Paper>
</Box>





















            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              position: "fixed",
              top: 0,
              left: { xs: 0, sm: 235 },
            }}
          >
            <Appheaderc />
          </Box>

        </Box>
      </div>
    );
  }
}

export default Start_Exampage;



